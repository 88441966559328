import { ElementRef, Injectable } from "@angular/core";
import { truthyFilter } from "@tellsy/rxjs/operators";
import {
  SpiralType,
  TagCloudFontWeight,
  TagCloudSettings,
  TagsData,
  TagWordSettings,
} from "@tellsy/theme/tag-cloud/model";
import D3 from "d3";
import cloud, { Word } from "d3-cloud";
import {
  asyncScheduler,
  BehaviorSubject,
  Observable,
  throttleTime,
} from "rxjs";
import _ from "underscore";

interface InternalSettings {
  skewArray: number[];
  colorArray: string[];
  minFontSize: number;
  fontDelta: number;
  fontFamily: string;
  fontWeight: TagCloudFontWeight;
  svgSize: {
    width: number;
    height: number;
  };
  spiralType: SpiralType;
  padding: number;
}

@Injectable({
  providedIn: "root",
})
export class TagCloudWorkerService {
  private defaultSettings = {
    margin: {
      top: 20,
      right: 20,
      bottom: 20,
      left: 20,
    },
    size: {
      width: 600,
      height: 600,
    },
  };

  private parentElement: ElementRef<HTMLElement>;
  private cloud: D3.layout.Cloud<Word>;

  private internalSettings: InternalSettings;
  // TODO: optimize rendering

  private svg: SVGSVGElement;
  private g: SVGGElement;

  private updates$: BehaviorSubject<SVGSVGElement | null> = new BehaviorSubject(
    null,
  );

  getUpdates$(): Observable<SVGSVGElement | null> {
    return this.updates$
      .asObservable()
      .pipe(
        truthyFilter(),
        throttleTime(1000, asyncScheduler, { trailing: true }),
      );
  }

  getImageString(withoutScale = false): string {
    this.checkHealth();

    if (withoutScale) {
      this.svg.removeAttribute("transform");
    }

    const serializer = new XMLSerializer();
    return `data:image/svg+xml,${encodeURIComponent(
      '<?xml version="1.0" encoding="UTF-8" standalone="no"?>' +
        serializer.serializeToString(this.svg),
    )}`;
  }

  async getImageAsPNG(): Promise<string> {
    return new Promise((resolve) => {
      this.checkHealth();

      const { width, height } = this.internalSettings.svgSize;
      const padding = 30;

      const canvas = document.createElement("canvas");
      canvas.setAttribute("width", String(width + 2 * padding));
      canvas.setAttribute("height", String(height + 2 * padding));

      const image = new Image(width, height);
      image.src = this.getImageString(true);
      image.onload = () => {
        const context = canvas.getContext("2d");

        if (!context) {
          console.error("Error on canvas context for tag cloud image");
          return;
        }

        context.drawImage(
          image,
          padding,
          padding,
          width + padding,
          height + padding,
        );

        const dataUrl = canvas.toDataURL();
        image.remove();
        canvas.remove();

        resolve(dataUrl);
      };
    });
  }

  init(parentElement: ElementRef) {
    this.parentElement = parentElement;

    this.svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    this.g = document.createElementNS("http://www.w3.org/2000/svg", "g");
    this.svg.appendChild(this.g);

    this.cloud = cloud().on("end", (words: Word[]) => {
      this.placeWords.bind(this)(words);
      this.cloud.stop();
    });
  }

  recalculate(tagsData: TagsData, settings: TagCloudSettings) {
    if (!settings) {
      return;
    }

    this.checkHealth();
    this.updateInternalSettings(settings, tagsData.maxCount);
    this.updateSize(settings.width, settings.height);
    this.updateTags(tagsData);
    this.updates$.next(this.svg);
  }

  private checkHealth() {
    if (!this.parentElement) {
      this.createDefaultParent();
    }
    if (!this.svg) {
      this.init(this.parentElement);
    }
  }

  private createDefaultParent() {
    console.warn("TagCloudWorkerService: Used default element");

    const el = document.createElement("div");
    this.parentElement = new ElementRef(el);
  }

  private updateInternalSettings(settings: TagCloudSettings, maxCount: number) {
    const {
      skewFrom,
      skewTo,
      orientations,
      minFontSize,
      maxFontSize,
      colorTheme: colorArray,
      fontFace: fontFamily,
      fontWeight,
      spiralType,
      padding,
    } = settings;

    const step = (Math.abs(skewFrom) + Math.abs(skewTo)) / orientations;
    const skewArray = new Array(orientations)
      .fill(null)
      .map((el, index) => skewFrom + step * index);

    const fontDelta = Math.floor((maxFontSize - minFontSize) * (1 - (0.1 * maxCount)));

    this.internalSettings = {
      skewArray,
      colorArray,
      minFontSize,
      fontDelta,
      fontFamily,
      fontWeight,
      svgSize: this.internalSettings?.svgSize,
      spiralType,
      padding,
    };
  }

  private updateSize(widthInPercents: number, heightInPercents: number): void {
    let { clientWidth, clientHeight } = this.parentElement.nativeElement;
    if (clientWidth === 0) {
      clientWidth = this.defaultSettings.size.width;
    }
    if (clientHeight === 0) {
      clientHeight = this.defaultSettings.size.height;
    }
    const { top, right, bottom, left } = this.defaultSettings.margin;

    const width = ((clientWidth - left - right) * widthInPercents) / 100;
    const height = ((clientHeight - top - bottom) * heightInPercents) / 100;

    const scale = Math.min(clientWidth / width, clientHeight / height);

    this.internalSettings.svgSize = { width, height };

    this.svg.setAttribute("width", String(width));
    this.svg.setAttribute("height", String(height));
    this.svg.setAttribute("transform", `scale(${scale})`);

    this.g.setAttribute("transform", `translate(${width / 2}, ${height / 2})`);
  }

  private updateTags(tagsData: TagsData) {
    const { skewArray, minFontSize, fontDelta, svgSize, spiralType, padding } =
      this.internalSettings;

    const longestWord = tagsData.tags?.reduce((max, tag) =>
      tag.text.length > max.text.length ? tag : max, { text: '' }
    ).text;

    const maxFontSize = Math.min(Math.floor(svgSize.width / (longestWord.length * 0.6)), minFontSize + fontDelta)
    const percentageDecrease = 0.1;
    const sortedTags = [...tagsData.tags].sort((a, b) => b.count - a.count);

    let previousSize = maxFontSize;
    let previousCount = sortedTags[0].count;

    const tags = sortedTags.map((tag, index) => {
      let size: number;
      if (index === 0) {
        size = maxFontSize;
      } else {
        if (tag.count < previousCount) {
          size = previousSize * (1 - percentageDecrease);
          size = Math.max(size, minFontSize);
          previousSize = size;
          previousCount = tag.count;
        } else {
          size = previousSize;
        }
      }

      return {
        text: tag.text,
        rotate: _.sample(skewArray),
        size: Math.round(size),
      };
    });

    this.cloud
      .words(tags) /*([...this.words, ...tags])*/
      .size([svgSize.width, svgSize.height])
      .fontSize((d) => d.size)
      .rotate((d) => d.rotate)
      .text((d) => d.text)
      .spiral(spiralType)
      .padding(padding)
      .start();
  }

  private placeWords(words: Word[]) {
    const { colorArray, fontFamily, fontWeight } = this.internalSettings;

    while (this.g.lastChild) {
      this.g.lastChild.remove();
    }

    words.forEach((word) => {
      const el = document.createElementNS("http://www.w3.org/2000/svg", "text");

      const elSettings: TagWordSettings = {
        "fill": _.sample(colorArray),
        "font-family": fontFamily,
        "font-size": String(word.size),
        "font-weight": String(fontWeight),
        "text-anchor": "middle",
        "transform": `translate(${word.x}, ${word.y}) rotate(${word.rotate})`,
      };

      el.innerHTML = word.text || "";
      Object.entries(elSettings).forEach(([k, v]) => el.setAttribute(k, v));

      this.g.appendChild(el);
    });
  }

  reset(): void {
    this.updates$.next(null);

    if (this.svg) {
      while (this.svg.lastChild) {
        this.svg.lastChild.remove();
      }
    }

    this.internalSettings = {
      skewArray: [],
      colorArray: [],
      minFontSize: 0,
      fontDelta: 0,
      fontFamily: '',
      fontWeight: TagCloudFontWeight.NORMAL,
      svgSize: { width: 0, height: 0 },
      spiralType: SpiralType.ARCHIMEDEAN,
      padding: 0,
    };

    this.parentElement = null;

    if (this.cloud) {
      this.cloud.stop();
      this.cloud = null;
    }

    this.svg = null;
    this.g = null;
  }
}
